import React from "react"

import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"

const IndexPage = () => (
  <Layout title="Huisregels">
    <PageTitle title="Huisregels" />
  </Layout>
)

export default IndexPage
